import React from 'react';
import { useState, useEffect, useRef } from 'react';
import corazonFondo from './img/fondo-corazon.png';
import monitos from './img/fondo.png';
import pingu from './img/mask-pingu.png';
import pico02 from './img/pico-02.png';
import pico01 from './img/pico-01.png';
import oreja02 from './img/oreja-02.png';
import oreja01 from './img/oreja-01.png';
import rabit from './img/mask-conejo.png';
import corazon from './img/corazon.png';
import cancion from './audios/only-one.mp3';
import './app.sass';


function App() {

  const [anch, setAnch] = useState('');
  const ref = useRef('');
  const audio = useRef('');
  const [ancho_, setAncho_] = useState(0);
  const [ancho, setAncho] = useState(window.innerWidth);

  useEffect(() => {

    let medida = ancho => {
      if (ancho >= 819) {
        setAnch(1);
      }else{
        noCentrado(ancho);
      }
    }
  
    let noCentrado = ancho => {
      let medida = (ancho*1)/(819+48);
      setAnch(medida);
      console.log('medida', medida);
    }

    let pantalla = () => {
      setAncho(window.innerWidth);
      console.log('pantalla', ancho);
      medida(ancho);
      console.log('ilustracion', ancho_);
      setAncho_(ref.current ? ref.current.offsetWidth : 0);
      audio.current.play();
    }

    window.addEventListener('load', pantalla);
    window.addEventListener('resize', pantalla);

  },[ancho, ancho_, setAncho_]);

  return (
    <div className="App">
      <audio autoplay loop ref={audio}>
        <source src={cancion} type="audio/mpeg"/>
        Your browser does not support the audio element.
      </audio>
      <header>
        <p title="Aniversario 8, 25 de abril de 2020" >a82020</p>
        <a href="https://a72019.besprone.com.mx/" target="_blank" rel="noopener noreferrer">a72019</a>
      </header>
      <div 
        className="content" 
        ref={ref}
        style = {
          {
            "-ms-transform": "scale("+anch+")",
            "transform": "scale("+anch+")"
          }
        }
      >
        <img className='fondoCorazon' src={corazonFondo} alt="corazon"/>
        <div className='circulos'>
          <div className='circulo01'></div>
          <div className='circulo02'></div>
          <div className='circulo03'></div>
        </div>
        <img className='monitos' src={monitos} alt="monitos"/>
        <div className='pinguino'>
          <img className='pingu' src={pingu} alt="pingu"/>
          <img className='pico02' src={pico02} alt="pico02"/>
          <img className='pico01' src={pico01} alt="pico01"/>
        </div>
        <div className='conejo'>
          <img className='oreja02' src={oreja02} alt="oreja02"/>
          <img className='rabit' src={rabit} alt="rabit"/>
          <img className='oreja01' src={oreja01} alt="oreja01"/>
        </div>
        <img className='corazon' src={corazon} alt="corazon"/>
        <div className="anios">
          <p>8°</p>
          <p>Aniversario</p>
        </div>
        <div className="linea02"></div>
      </div>
    </div>
  );
}

export default App;
